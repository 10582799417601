import { Box } from '@chakra-ui/react';
import React from 'react';
import { useApi } from '../lib/useApi';
import Pie from 'components/Donut';
import { NumberCard } from 'components/NumberCard';
import { orderTable, eventTable } from 'components/DataTable/DataTableModels';
import { Container } from 'components/Container';
import { ContainerHead } from 'components/ContainerHead';
import { DataTable } from 'components/DataTable/DataTable';
import { Order } from 'models/ApiModels';

export function StartPage(): JSX.Element {
  const { data: ordersResp } = useApi<Order[]>('/orders');
  const statsResp = useApi<{ [key: string]: number }>('/statistics');
  // const { data: eventsResp } = useApi<Event[]>('/events');
  return (
    <>
      <ContainerHead backButton={false}>
        <Box>
          {statsResp.data && <Pie
            data={{
              min: 0,
              max: 100,
              value: parseFloat((100 - statsResp.data?.totalVacancy ?? 0).toFixed(2)),
              label: 'Beläggningsgrad',
            }}
            width={210}
            height={210}
          />}
        </Box>
        <NumberCard
          title="Totalt antal artiklar"
          number={statsResp.data?.totalUnits ?? 0}
          fontSize={42}
        />
        <NumberCard
          title="Totalt antal lediga artiklar"
          number={statsResp.data?.totalAvailableUnits ?? 0}
          fontSize={42}
        />
        <NumberCard
          title="Antal aktiva avtal"
          number={statsResp.data?.totalRentedUnits ?? 0}
          fontSize={42}
        />
      </ContainerHead>
      <Container>
        <DataTable
          tableDef={orderTable}
          data={ordersResp}
          searchableFields={[]}
          title="Senaste ordrar"
          showSearch={false}
          hitsPerPage={5}
        />
        {/* <DataTable
          tableDef={eventTable}
          data={eventsResp}
          searchableFields={[]}
          title="Senaste händelser"
          showSearch={false}
          hitsPerPage={10}
        /> */}
      </Container>
    </>
  );
}
